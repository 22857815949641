//@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1440px,
        xxl: 1920px
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1100px,
        xl: 1140px,
        xxl: 1400px
);
$container-padding-x: 22px;
$spacer: 1rem !default;
$spacers: (
        0: 0,
        '05': 5px,
        1: 10px,
        2: 20px,
        3: 30px,
        4: 40px,
        5: 50px,
        6: 60px,
        7: 70px,
        10: 100px,
) !default;

$font-weight-medium: 500;
$font-weight-semi-bold: 500;
$font-weight-bolder: 800;
$font-weight-black: 900;

$font-sizes: (
        1: 60px,
        2: 50px,
        3: 40px,
        4: 32px,
        5: 28px,
        6: 24px,
        7: 20px,
        8: 16px,
        9: 14px,
        10: 12px,
) !default;

// 3. Include remainder of required Bootstrap stylesheets

@import "../../node_modules/bootstrap/scss/variables";

@import "../../node_modules/bootstrap/scss/mixins";
@mixin make-container($gutter: $container-padding-x) {
    width: 100%;
    max-width: 1720px;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

$utilities: () !default;
$utilities: map-merge(
                (
                        "font-weight": (
                                property: font-weight,
                                class: fw,
                                values: (
                                        light: $font-weight-light,
                                        lighter: $font-weight-lighter,
                                        normal: $font-weight-normal,
                                        medium: $font-weight-medium,
                                        semi: $font-weight-semi-bold,
                                        bold: $font-weight-bold,
                                        bolder: $font-weight-bolder,
                                        black: $font-weight-black
                                )
                        ),
                ),
                $utilities
);
// 4. Include any optional Bootstrap components as you like
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/bootstrap-utilities";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/transitions";

