.story {
    .content-wrapper {
        padding-top: 74px;
        height: 100vh;
        @media (min-width: 1024px) {
            padding: 0;
            height: 100vh;
        }

        .parallax-image {
            height: 30vh;
            @media (min-width: 576px) {
                height: 50vh;
            }
            @media (min-width: 1024px) {
                height: 100vh;
            }

            img {
                transition: transform 0.3s ease-in-out;
            }

        }

        .fp-slides {
            display: none;
            @media (min-width: 1024px) {
                visibility: hidden;
                pointer-events: none;
                position: absolute
            }

        }

        .fp-arrow {
            display: none;
        }

        .hide {
            opacity: 0 !important;
        }

        .active-text {
            transition: all 0.3s ease-in-out;
            position: absolute;
            //bottom: 100px;
            left: 100px;
            max-width: 900px;
            background: rgba(104, 104, 104, 0.28);
            mix-blend-mode: normal;
            border: 2px solid rgba(255, 255, 255, 0.2);
            box-shadow: 0 0 26px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(30px);
            border-radius: 28px;
            font-size: 14px;
            @media (min-width: 1024px) {
                font-size: 18px;
                left: 50px;
                bottom: 50px;
            }
            @media (min-width: 1440px) {
                font-size: 22px;
                left: 70px;
                bottom: 70px;
            }
            @media (min-width: 1920px) {
                font-size: 24px;
                left: 100px;
                bottom: 100px;
            }

            .text {
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
            }
        }

        .story-swiper {
            .swiper-wrapper {
                align-items: stretch;
                @media (min-width: 1024px) {
                    position: absolute;
                    bottom: 0;
                }

                .swiper-slide {

                    p {
                        opacity: 0 !important;
                        @media (min-width: 768px) {
                            font-size: 22px;
                        }
                    }

                    height: auto;

                    &-active {
                        p {
                            opacity: 1 !important;
                            transition: opacity .3s ease-in-out;
                        }
                    }

                    @media (min-width: 1024px) {
                        p {

                        }
                    }
                }
            }

            .swiper-pagination {
                .swiper-pagination-bullet {
                    background-color: #39476D;
                    opacity: 1;
                    width: 8px;
                    height: 4px;
                    margin: 0 3px;
                    border-radius: 3px;
                    text-align: center;

                    &-active {
                        width: 16px;
                        background-color: #CDCFD6;
                    }
                }
            }
        }
    }
}