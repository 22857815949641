*, ::after, ::before {
    box-sizing: border-box !important;
}

html {
    ::-webkit-scrollbar {
        display: none;
    }
}

body {
    font-family: 'Rubik', sans-serif;
    background-color: #051130;
    color: #fff;
    font-size: 16px;
    margin: 0;
    letter-spacing: 0.06em;
    > iframe {
        display: none !important;
    }
}

h1 {
    line-height: 1.4;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}


p {
    color: #fff !important;
    margin: 0;

    span {
        color: #fff !important;
    }
}

section {
    h2 {
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 0.06em;
        @media (min-width: 1024px) {
            font-size: 48px;
            line-height: 48px;
        }
    }
}

.modal-content {
    background-color: #1C1C1C;
    border-radius: 20px;

    .modal-header {
        border: none;
        padding: 40px 20px 20px;
        @media (min-width: 1024px) {
            padding: 50px 50px 20px;
        }

        h5 {
            padding-right: 20px;
            color: #A88436;
            font-size: 18px;
            line-height: 24px;
            @media (min-width: 1024px) {
                font-size: 36px;
                line-height: 48px;
            }
        }

        .btn-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;

            &:hover {
                svg {
                    path {
                        fill: #fff
                    }
                }
            }
        }
    }

    .modal-body {
        padding: 0 20px;
        @media (min-width: 1024px) {
            padding: 0 50px 50px;
        }

        p {
            margin-bottom: 10px;
        }
    }
}

.swiper-pagination {
    .swiper-pagination-bullet {
        background-color: #39476D;
        opacity: 1;
        width: 8px;
        height: 4px;
        margin: 0 3px;
        border-radius: 3px;

        &-active {
            width: 16px;
            background-color: #CDCFD6;
        }
    }
}

.fp-slidesNav {
    left: 17px !important;
    @media (min-width: 1024px) {
        display: none;
    }

    ul {
        li {
            margin: 0;
            width: auto !important;
            height: auto !important;
            transition: none !important;

            a {
                transition: none !important;

                span {
                    display: none;
                    transition: none !important;
                }

                .fp-sr-only {
                    transition: none;
                    position: static;
                    display: block;
                    font-size: 0;
                    background-color: #39476D;
                    opacity: 1;
                    width: 8px !important;
                    height: 4px !important;
                    margin: 0 3px !important;
                    border-radius: 3px !important;
                    @media (min-width: 576px) {
                        width: 20px !important;
                        height: 6px !important;
                    }
                }

                &.active {
                    transition: none !important;

                    span {
                        margin: 0 3px !important;
                        width: 16px !important;
                        height: 4px !important;
                        background-color: #CDCFD6;

                        @media (min-width: 576px) {
                            width: 30px !important;
                            height: 6px !important;
                        }
                    }

                }
            }
        }
    }
}

.fp-arrow {
    display: none !important;
}