nav {
    background: #051130;
    z-index: 3;
    @media (min-width: 1024px) {
        height: 150px;
        background: linear-gradient(180deg, #051130 0%, rgba(5, 17, 48, 0) 100%);
    }
    @media (min-width: 1440px) {
        height: 200px;
    }
    @media (min-width: 1920px) {
        height: 280px;
    }

    .logo {
        height: 34px;
        margin-right: auto;
        position: relative;
        z-index: 8;
        cursor: pointer;
        @media (min-width: 1024px) {
            height: 68px;
        }
        path {
            transition: fill 0.2s ease-in-out;
        }
        &:hover {
            .red {
                fill: #9F060E;
            }
            .dark-red {
                fill: #7B0D18
            }
        }
    }

    .navigation, .mobile-menu {
        li {
            a {
                color: #fff;
                opacity: 0.5;
                transition: opacity 0.2s ease-in-out;
                font-size: 16px;
                letter-spacing: 0.06em;

                cursor: pointer;
                &:hover {
                    opacity: 1;
                }
            }

            &.active {
                font-weight: 700;

                a {
                    opacity: 1;
                }
            }
        }
    }

    .socials {
        li {
            a {
                opacity: 0.8;
            }
        }
    }

    .socials {
        margin-left: auto;

        li:first-child {
            a {
                img {
                    height: 20px;
                    @media (min-width: 1024px) {
                        height: 26px
                    }
                }
            }
        }

        a {
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 0.5;
            }

            img {
                height: 18px;
                @media (min-width: 1024px) {
                    height: 24px
                }
            }
        }
    }

    .mobile-menu-container {
        //transform: translate(100%, -100%);
        transition: opacity 0.2s ease-in-out;
        background-image: url("../../../assets/images/header-bg-mobile.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom;
        background-color: #051130;
        opacity: 0;
        pointer-events: none;

        &.show {
            opacity: 1;
            pointer-events: auto;
        }
    }

    .hamburger {
        cursor: pointer;
    }


    .mobile-menu {
        width: 70%;
        box-shadow: 0 0 26px rgba(0, 0, 0, 0.15);
        background: rgba(104, 104, 104, 0.2);
        backdrop-filter: blur(30px);
        border: 1px solid rgba(255, 255, 255, 0.23);
        border-radius: 12px;
        mix-blend-mode: normal;
    }

    .divider {
        height: 1px;
        width: 70px;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.23);
    }

    .close {
        color: #D10913;
        cursor: pointer;
    }
}