.roadmap {
    overflow: hidden;

    .content-wrapper {
        padding-top: 74px;
        height: 100vh;
        @media (min-width: 1024px) {
            padding: 0;
        }
    }

    .roadmap-swiper {
        z-index: 3;
    }

    .fp-slides {
        visibility: hidden;
        pointer-events: none;
        position: absolute
    }

    .fp-arrow {
        display: none;
    }

    .swiper-slide {
        opacity: 0 !important;
        height: auto;

        &-active {
            opacity: 1 !important;
        }

        .content-container {
            @media (min-width: 1024px) {
                height: 100vh;
            }

            .roadmap-content {
                padding: 0 20px;
                max-width: 70%;

                @media (min-width: 768px) {
                    padding: 50px 30px 0;
                    font-size: 22px;

                }
                @media (min-width: 1024px) {
                    padding: 100px 50px 0;
                    font-size: 28px;
                }
                @media (min-width: 1440px) {
                    padding: 150px 70px 0;
                    font-size: 32px;
                    max-width: 80%;
                }
                @media (min-width: 1920px) {
                    padding: 180px 100px 0;
                    font-size: 36px;
                    max-width: 100%;
                }
            }
        }


        .last-slide {
            //padding: 0 20px;
            @media (min-width: 768px) {
                padding-top: 90px;
                max-width: 80%;
            }
            @media (min-width: 1024px) {
                max-width: none;
            }
            //@media (min-width: 1440px) {
            //    padding: 0 70px;
            //}
            //@media (min-width: 1920px) {
            //    padding: 0 100px;
            //}

            >div {
                font-size: 16px;
                @media (min-width: 768px) {
                    font-size: 22px;
                }
                @media (min-width: 1024px) {
                    font-size: 28px;
                }
                @media (min-width: 1440px) {
                    font-size: 36px;
                }
            }

            >span {
                font-size: 14px;
                @media (min-width: 768px) {
                    font-size: 16px;
                }
                @media (min-width: 1024px) {
                    font-size: 18px;
                }
                @media (min-width: 1440px) {
                    font-size: 24px;
                }
            }
            >p {
                img {
                    width: 100px;
                }
            }
        }

        .image {
            //height: calc((100vh - 74px) / 2);
            height: 240px;
            margin-bottom: 16vh;
            @media (min-width: 1024px) {
                height: 100vh;
                margin-bottom: 0;
            }

            .image-container {
                //margin-bottom: 100px;
                //background-image: none;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                @media (min-width: 1024px) {
                    padding: 50px;
                    height: 100vh;
                    margin-bottom: 0;

                }

                @media (min-width: 1440px) {
                    padding: 70px;
                }
                @media (min-width: 1920px) {
                    padding: 100px;
                }

                p {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    left: 20px;
                    background: rgba(104, 104, 104, 0.2);
                    mix-blend-mode: normal;
                    border: 1px solid rgba(255, 255, 255, 0.23);
                    box-shadow: 0 0 26px rgba(0, 0, 0, 0.15);
                    backdrop-filter: blur(30px);
                    border-radius: 12px;
                    font-size: 14px;
                    @media (min-width: 768px) {
                        right: 30px;
                        left: 30px;
                        font-size: 18px;
                        bottom: 30px;
                    }
                    @media (min-width: 1024px) {
                        width: 100%;
                        font-size: 18px;
                        margin: 0;
                        position: static;
                        bottom: 50px;
                    }
                    @media (min-width: 1440px) {
                        border: 2px solid rgba(255, 255, 255, 0.23);
                        border-radius: 28px;
                        font-size: 22px;
                        bottom: 70px;
                    }
                    @media (min-width: 1920px) {
                        font-size: 24px;
                    }
                }

                & + div {
                    max-width: 70%;
                }
            }
        }

    }

    .fp-watermark {
        display: none;
    }

    .travel-container {
        width: 100%;
        height: calc(100vh - 395px);
        bottom: 0;
        display: flex;
        justify-content: flex-end;

        @media (min-width: 768px) {
            height: 53vh;
        }
        @media (min-width: 1024px) {
            height: auto;
            width: 50%;
            top: 0;
            right: 0;
            bottom: 0;
            justify-content: center;
            align-items: flex-end;
        }

        .svg-container {
            height: 100%;
            width: 100%;
            max-height: 470px;
            flex-shrink: 0;
            display: flex;
            justify-content: flex-end;
            position: relative;
            @media (min-width: 1024px) {
                width: 100%;
                height: auto;
                max-height: 100%;
                margin: 0 100px;
                display: flex;
                flex-shrink: 1;
                justify-content: center;
                position: relative;
                svg {
                    width: 100%;
                    transform: rotate(-65deg);
                }
            }
            @media (min-width: 1440px) {
                margin: 0 180px;
            }
            @media (min-width: 1920px) {
                margin: 0 200px;
            }

        }

        .mars-container {
            transition: all 0.3s ease-in-out;
            right: 7%;
            bottom: 11%;
            width: 16%;
            border-radius: 50%;

            &:before {
                content: '';
                position: absolute;
                border-radius: 50%;
                inset: 0 4px 2px 1px;
                z-index: 0;
                box-shadow: -3px -3px 20px #9a1128;
            }

            @media (min-width: 1024px) {
                width: 20%;
                right: 0;
                top: 200px;
                bottom: auto;
            }
            @media (min-width: 1920px) {
                width: 20%;
                right: 0;
                top: 220px;
                bottom: auto;
            }

            .mars {
                //filter: drop-shadow(-3px -3px 15px #9a1128);
                z-index: 1;
            }


            &.active {
                &:before {
                    inset: 0 8px 10px 2px;
                    box-shadow: -10px -10px 120px #9a1128;
                }

                width: 50%;
                bottom: 20px;
                @media (min-width: 1024px) {
                    width: 100%;
                    top: 0;
                    //right: auto;
                    bottom: auto;
                    //transform: translate(50%, 50%);
                }
            }

            &.show {
                .building1, .building2, .building3, .building4 {
                    transform: translate(0, 0);
                    opacity: 1;
                }
            }

            > img:not(.mars) {
                z-index: 0;
            }

            .building {
                opacity: 0;
                transition: all 0.3s ease-in-out;
            }

            .building1 {
                top: -37%;
                left: -4%;
                width: 40%;
                transform: translate(30px, 55px);
            }

            .building2 {
                left: 20%;
                width: 35%;
                top: -42%;
                transform: translate(15px, 50px);
            }

            .building3 {
                right: 14%;
                width: 35%;
                top: -54%;
                transform: translate(-15px, 80px);
            }

            .building4 {
                right: -9%;
                width: 45%;
                top: -21%;
                transform: translate(-25px, 50px);
            }
        }
    }


    svg {
        //margin-top: -20%;
        height: 100%;
        transition: opacity 0.3s ease-in-out;
        @media (min-width: 1024px) {
            margin: 0;
        }

        circle {
            filter: blur(3px);
        }

        .earth {
            filter: drop-shadow(0px 0px 5px #ACFFFB);
        }

        .paint5_linear_27_52 {
            filter: drop-shadow(0px 0px 5px #ACFFFB);
        }
    }

    #Rocket {
        transform-origin: 80px 180px;
        transition: transform 0.3s ease-in-out;

        &.step1 {
            transform: rotate(0deg);
        }

        &.step2 {
            transform: rotate(20deg);
        }

        &.step3 {
            transform: rotate(45deg);
        }

        &.step4 {
            transform: rotate(65deg);
        }

        &.step5 {
            transform: rotate(88deg);
        }
    }
}

body {
    //>div:last-of-type {
    //    opacity: 0;
    //}
}


