header {
    height: 100vh;
    background-image: url("../../../assets/images/header-bg-mobile.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    @media (min-width: 576px) {
        background-image: url("../../../assets/images/header-bg-desktop.png");
        background-size: 250%;
        background-position: bottom center;
    }


    @media (min-width: 768px) {
        background-size: 300%;
        background-position: bottom center;
    }
    @media (min-width: 1024px) {
        background-size: 120%;
        background-position: bottom;
    }
    @media (min-width: 1920px) {
        background-size: 100%;
        background-position: bottom;
    }


    .header-container {
        padding-top: 80px;
        @media (min-height: 600px) {
            padding-top: 90px;
        }
        @media (min-width: 768px) {
            padding-top: 150px;
        }

        @media (min-width: 1024px) {
            padding-top: 150px;
        }
        @media (min-width: 1920px) {
            padding-top: 120px;
        }

        h1 {
            font-size: 18px;
            @media (min-width: 375px) {
                font-size: 22px;
            }
            @media (min-width: 768px) {
                font-size: 32px;
            }
            @media (min-width: 1920px) {
                font-size: 50px;
            }
        }

        .subtitle {
            font-size: 14px;

            @media (min-width: 768px) {
                font-size: 20px;
            }
        }

        .nft_calendar {
            img {
                @media (max-width: 768px) {
                    width: 70px;
                }
            }
        }

        .crocs-count {
            padding: 14px 0;
            background: rgba(104, 104, 104, 0.2);
            mix-blend-mode: normal;
            border: 1px solid rgba(255, 255, 255, 0.23);
            box-shadow: 0 0 26px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(30px);
            border-radius: 12px;
            @media (min-width: 1440px) {
                border: 2px solid rgba(255, 255, 255, 0.23);
                border-radius: 28px;
            }

            > div:not(:last-of-type) {
                border-right: 1px solid rgba(255, 255, 255, 0.23);
                @media (min-width: 1440px) {
                    border-right: 2px solid rgba(255, 255, 255, 0.23);
                }
            }

            h4 {
                font-size: 20px;
                margin-bottom: 5px;

                @media (min-width: 768px) {
                    font-size: 28px;
                }
                @media (min-width: 1024px) {
                    font-size: 32px;
                }

                @media (min-width: 1440px) {
                    font-size: 48px;
                }
                @media (min-width: 1920px) {
                    font-size: 60px;
                }
            }

            p {
                font-size: 14px;
                line-height: 16px;

                @media (min-width: 1920px) {
                    font-size: 20px;
                }
            }
        }
    }

    .crocs-container {
        max-width: 500px;
        flex-shrink: 0;
        @media (min-width: 768px) {
            max-width: 700px;
        }
        @media (min-width: 1024px) {
            max-width: 400px;
        }
        @media (min-width: 1440px) {
            max-width: 500px;
        }
        @media (min-width: 1920px) {
            max-width: 600px;
        }
        .crocs-slide {
            max-width: 59%;

            @media (min-height: 800px) {
                max-width: 75%;
            }
            @media (min-width: 576px) {
                max-width: 45%;
            }
            @media (min-width: 768px) {
                max-width: 52%;
            }
            @media (min-width: 1024px) {
                max-width: 100%;
            }
        }
    }
    .content-container {
        width: 100%;
    }

}