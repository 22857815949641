.subscribe-content {
    min-height: calc(100vh - 200px);
    position: relative;

    .content {

        @media (min-width: 576px) {
            margin-top: 60px;
        }
        @media (min-width: 768px) {
            margin-top: 80px;
        }
        @media (min-width: 1024px) {
            margin-bottom: 80px;
            margin-top: 120px;
            max-width: 400px;
        }
    }

    h2 {
        margin-bottom: 22px;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0.06em;
        @media (min-width: 1024px) {
            margin-bottom: 40px;
            font-size: 48px;
        }
    }

    input {
        margin-bottom: 8px;
        padding: 15px;
        width: 100%;
        background-color: #141414;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border-radius: 6px;
        border: 2px solid transparent;
        line-height: 18px;
        @media (min-width: 1024px) {
            font-size: 20px;
            margin-bottom: 8px;
        }

        &.error {
            border: 2px solid #AC5353;
        }

        ::placeholder {
            color: #4F4F4F;
        }

        &:focus {
            outline: none;
        }
    }
    .message {
        height: 18px;
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: 500;
        @media (min-width: 1024px) {
            margin-bottom: 20px;
        }
        &.error {
            color: #AC5353;
        }
    }
    button {
        margin-bottom: 20px;
    }

    .bull-desktop {
        max-width: 700px;
        width: 100%;
    }
}