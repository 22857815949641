.button {
  padding: 10px 24px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  border: none;
  border-radius: 12px;
  letter-spacing: 0.06em;
  transition: background-color 0.2s ease-in-out;


  @media (min-width: 1024px) {
    font-size: 20px;
    padding: 13px 30px;
  }

  &.contain {
    background-color: #D10913;

    &:hover {
      background-color: #9F060E;
    }

    &:disabled {
      background-color: #464646;
    }
  }

  &:focus-visible {
    outline: none;
  }
}