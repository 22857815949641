@font-face {
    font-family: 'icomoon';
    src:  url('../assets/fonts/icomoon.eot?r46d8o');
    src:  url('../assets/fonts/icomoon.eot?r46d8o#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?r46d8o') format('truetype'),
    url('../assets/fonts/icomoon.woff?r46d8o') format('woff'),
    url('../assets/fonts/icomoon.svg?r46d8o#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-discord:before {
    content: "\e900";
}
.icon-instagram:before {
    content: "\e901";
}
.icon-twitter:before {
    content: "\e902";
}
.icon-you-tube:before {
    content: "\e903";
}
